import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { useIntervalTimer } from 'ziphy-web-shared/basic/hooks';
import { formatDuration } from 'ziphy-web-shared/basic/utils/time';
const Duration = ({ from, to, format }) => {
    const [duration, setDuration] = useState(calcDuration());
    const [spanWidth, setSpanWidth] = useState(0);
    const timerRef = useRef(null);
    const { start, stop } = useIntervalTimer({
        callback: () => setDuration(calcDuration()),
        duration: 1000,
        autoStart: false,
    });
    useEffect(() => {
        if (!spanWidth && timerRef.current) {
            calculateSpanWidth();
        }
    }, [spanWidth]);
    useEffect(() => {
        if (!to) {
            start();
        }
        return () => stop();
    }, [to, start, stop]);
    function calcDuration() {
        const m1 = moment(from);
        const m2 = to ? moment(to) : moment.utc();
        return m2.diff(m1);
    }
    function calculateSpanWidth() {
        const timerEl = timerRef.current;
        if (timerEl) {
            const sampleEl = document.createElement('span');
            sampleEl.innerText = '4';
            sampleEl.style.color = 'transparent';
            sampleEl.style.position = 'absolute';
            sampleEl.style.visibility = 'hidden';
            timerEl.appendChild(sampleEl);
            const targetWidth = sampleEl.getBoundingClientRect().width;
            setSpanWidth(parseFloat(targetWidth.toFixed(1)));
            sampleEl.remove();
        }
    }
    function renderDuration(value, format) {
        const defaultFormat = value < 60 * 60 * 1000 ? 'mm:ss' : 'h:mm:ss';
        return formatDuration(value, format || defaultFormat);
    }
    return (_jsx("div", Object.assign({ ref: timerRef, style: {
            position: 'relative',
            display: 'inline-block',
            textAlign: 'left',
        }, className: "duration-wrapper" }, { children: renderDuration(duration, format)
            .split('')
            .map((char, index) => (_jsx("span", Object.assign({ style: {
                display: 'inline-block',
                width: char === ':' ? 'initial' : `${spanWidth}px`,
                textAlign: 'center',
            } }, { children: char }), `timer-item-${char}-${index}`))) })));
};
export default memo(Duration);
