import { APPT_COLOR_PALETTE } from './';
// todo: move apptTypes to $serviceStore entity?
export const apptTypes = {
    getType: (service) => {
        const isOnSite = (service === null || service === void 0 ? void 0 : service.agentLoad) === 'onsite';
        const isFull = (service === null || service === void 0 ? void 0 : service.agentLoad) && (service === null || service === void 0 ? void 0 : service.providerLoad);
        const isOnlyProvider = (service === null || service === void 0 ? void 0 : service.providerLoad) && !(service === null || service === void 0 ? void 0 : service.agentLoad);
        return `${isOnSite ? 'onsite' : 'virtual'}_${isFull ? 'full' : isOnlyProvider ? 'provider' : 'agent'}`;
    },
    getApptType: (service) => {
        const apptType = apptTypes.getBasicTypes(service);
        if (apptType.isAtClinic) {
            return 'at_clinic';
        }
        else if (apptType.isAtHome) {
            return 'at_home';
        }
        else {
            return 'virtual';
        }
    },
    getBasicTypes: (service) => {
        const { agentLoad, providerLoad, reasons } = service;
        // todo: migrate to ENUMS_APPT_VISIT_TYPE
        const result = {
            isVirtual: false,
            isAtHome: false,
            isAtClinic: false,
        };
        if (reasons === null || reasons === void 0 ? void 0 : reasons.includes('clinic')) {
            result.isAtClinic = true;
        }
        else {
            if ([null, 'virtual'].includes(agentLoad) && [null, 'virtual'].includes(providerLoad)) {
                result.isVirtual = true;
            }
            if (agentLoad === 'onsite' && [null, 'virtual'].includes(providerLoad)) {
                result.isAtHome = true;
            }
        }
        return result;
    },
    isOnSite(service) {
        return this.getBasicTypes(service).isAtHome;
    },
    isVirtual(service) {
        return this.getBasicTypes(service).isVirtual;
    },
    isWithAgent(service) {
        return Boolean(service.agentLoad);
    },
    isWithProvider(service) {
        return Boolean(service.providerLoad);
    },
    isStartAgent(service) {
        return Boolean(service.agentLoad);
    },
    isStartProvider(service) {
        return Boolean(service.providerLoad) && !service.agentLoad;
    },
    //
    isFull(service) {
        return Boolean(service.agentLoad) && Boolean(service.providerLoad);
    },
    isOnsiteAgent(service) {
        return service.agentLoad === 'onsite';
    },
    isVirtualProvider(service) {
        return service.providerLoad === 'virtual';
    },
};
export const getAppointmentColor = ({ status, patientGroupType, service, }) => {
    var _a;
    let result;
    if (['booked'].includes(status)) {
        result = (_a = APPT_COLOR_PALETTE.upcoming[apptTypes.getType(service)]) === null || _a === void 0 ? void 0 : _a[patientGroupType];
    }
    else if (status === 'started') {
        result = APPT_COLOR_PALETTE.inProgress;
    }
    else {
        result = APPT_COLOR_PALETTE.done;
    }
    return result;
};
